import React, { useState } from "react";
import '../../global.css';
import './CertificationPage.css';
import Saas from "../../assets/Pictures_and_videos/Software as a Service Cert.png";
import DevCodeCert from "../../assets/Pictures_and_videos/DevCodeCert.png";
import JavaCert from "../../assets/Pictures_and_videos/Basic Java Cert.png"
import JqueryCert from "../../assets/Pictures_and_videos/Jquery basic course compleation .png"

const CertificationsPage = () => {
  const [zoomed, setZoomed] = useState(null);

 
  const handleImageClick = (image) => {
    setZoomed(image);
  };

  return (
    <section>
      <h1 className="cert-style">Certifications</h1>
      <div className="container text-center">
        <div className="row">
          <div className="col">
            <figure onClick={() => handleImageClick(Saas)}>
             <img src={DevCodeCert} alt="DevCodeCamp Certificate of Completion" width={270} />
              <figcaption>DevCodeCamp Certificate of Completion</figcaption>
            </figure>
          </div>
          <div className="col">
            <figure onClick={() => handleImageClick(DevCodeCert)}>
            <img src={Saas} alt="Essentials of Software-as-a-service(SaaS)Business" width={270} />
              <figcaption>Essentials of Software-as-a-service(SaaS)Business</figcaption>
            </figure>
          </div>
          <div className="col">
          <figure onClick={() => handleImageClick(JavaCert)}>
            <img src={JavaCert} alt="Basic Java and Intelli J IDEA" width={270} />
              <figcaption>Basic Java Programming with IntelliJ IDEA</figcaption>
            </figure>
          </div>
          <div className="col">
          <figure onClick={() => handleImageClick(JqueryCert)}>
            <img src={JqueryCert} alt="jQuery Crash Course: Learn the Essential of jQuery Fast" width={270} />
              <figcaption>jQuery Crash Course: Learn the Essential of jQuery Fast</figcaption>
            </figure>
          </div>
        </div>
        <div className="row">
          <div className="col-8"></div>
          <div className="col-4"></div>
        </div>
      </div>

      {zoomed && (
        <div className="image-zoomed" onClick={() => setZoomed(null)}>
          <img src={zoomed} alt="Zoomed Image" />
        </div>
      )}
    </section>
  );
}

export default CertificationsPage;


