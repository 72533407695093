import React from "react";
import "../../global.css";
import MadLibsVideo from "../../assets/Pictures_and_videos/MadLibsPHP.mp4";
import YouTubeCloneVideo from "../../assets/Pictures_and_videos/YoutubeClone.mp4";
import RPSLS from "../../assets/Pictures_and_videos/RockPaperLizardSpockVideo.mp4"
import TravelApp from "../../assets/Pictures_and_videos/Capstone.Travel App.mp4"
import "./ProjectPage.css";

const ProjectPage = () => {
  return (
    <div className="background">
      <h1><span className="project-style">Projects</span></h1>
      <div class="container text-center">
        <div class="row">
          <div class="col">
            <h3>YouTube Clone</h3>
            <a href="https://github.com/Reformedhope/Youtube_Clone" target="_blank">RPSLS GitHub</a>
            <video controls width="100%" height="auto" src={YouTubeCloneVideo} />
            <p>Languages: JavaScript, Python, CSS, and HTML</p>
          </div>
          <div class="col">
            <h3>PHP MadLibs</h3>
            <a href="https://github.com/Reformedhope/LearningPHP" target="_blank">Learning PHP/MadLibs</a>
            <video controls width="100%" height="auto" src={MadLibsVideo} />
            <p>Language: PHP</p>
          </div>
          <div class="col"><h3>RPSLS</h3>
          <a href="https://github.com/Reformedhope/Rock_paper_scissors_lizard_spock" target="_blank">RPSLS GitHub</a>
            <video controls width="100%" height="auto" src={RPSLS} />
            <p>Language: Python</p>
            </div>
            
{/* //2nd row of projects  */}
            {/* <div class="row">
          <div class="col">
            <h3>Travel Application</h3>
            <a href="https://github.com/Reformedhope/Vacation_planning_Flex_App" target="_blank">Travel Application</a>
            <video controls width="400" height="300" src={TravelApp} />
            <p>Languages: JavaScript, Python, CSS, and HTML</p>
            <p>Other: Bootstrap, Django, MYSQL, Node.js, Axios</p>
          </div>
          <div class="col">
            <h3>PHP MadLibs</h3>
            <a href="https://github.com/Reformedhope/LearningPHP" target="_blank">Learning PHP/MadLibs</a>
            <video controls width="400" height="300" src={MadLibsVideo} />
            <p>Language: PHP</p>
          </div>
          </div> */}
          
        </div>
      </div>
    </div>
  );
};

export default ProjectPage;
