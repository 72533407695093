import React from "react";
import { useNavigate } from "react-router-dom";
import './NavBar.css';
import Github from "../../assets/Pictures_and_videos/github.jpg";

const NavBar = () => {
  
  return (
    //Bootstrap Styling
    <div className="navbar-fixed">
      <div className="navbar d-flex justify-content-center align-items-center">
        <ul className="nav nav-underline">
          <li className="nav-item">
            <a className="nav-link " aria-current="page" href="/">Home</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/projects">Projects</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/certifications">Certificates</a>
          </li>
          <li className="nav-item">
            <a className="nav-link " aria-current="page" href="https://www.linkedin.com/in/stephanie-salzburg-5a2882253/">LinkedIn</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="https://github.com/Reformedhope">Github</a>
          </li>
        </ul>
      </div>
    </div>
  );
};
    
export default NavBar;
