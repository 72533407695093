import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './Pages/HomePage/HomePage';
import Footer from './Components/Footer/Footer';
import NavBar from './Components/NavBar/NavBar';
import CertificationsPage from './Pages/CertificationsPage/CertificationsPage';
import ProjectPage from './Pages/ProjectsPage/ProjectsPage';
import "./App.css";
import './global.css';

function App() {


  
  return (
    <div className='background'>
   
    <Router>
      <NavBar/>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path ="/projects" element = {<ProjectPage/>}/>
        <Route path ="/certifications" element = {<CertificationsPage/>}/>
      </Routes>
    </Router>
    <Footer/>
    </div>
  
  );
}

export default App;