const Other = () => {


    return ( 
        <div>
            <h2>Other</h2>
            <ul>
                <li>MySQL</li>
                <li>Github</li>
                <li>Git</li>
                <li>Vercel</li>
                <li>VS Code</li>
                <li>Visual Studio</li>
                <li>Docker</li>
                <li>IntelliJ IDEA</li>

            </ul>
        </div>
     );
}
 
export default Other;