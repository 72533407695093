import { useEffect } from "react";
import React from "react";
import Typed from "typed.js";

//css page
import "./homepage.css";

//imported components
import portfoliophoto from "../../assets/Pictures_and_videos/picture.jpg";
import Frontend from "../../Components/ScrollPhotoLanguages/Frontend";
import Backend from "../../Components/ScrollPhotoLanguages/Backend";
import Other from "../../Components/ScrollPhotoLanguages/Other";
import Github from "../../assets/Pictures_and_videos/github.jpg";
import Linkedin from "../../assets/Pictures_and_videos/LinkedIn.png";

const HomePage = () => {
  useEffect(() => {
    const existingTyped = new Typed("#typed-text", {
      strings: [
        "Front-End Developer",
        "Full Stack Developer",
        "Problem Solver",
        "Back-End Developer",
        "Tester",
        "PHP Developer",
      ],
      typeSpeed: 100,
      backSpeed: 100,
      loop: true,
    });

    // Clean up the Typed instance when the component unmounts
    return () => {
      existingTyped.destroy();
    };
  }, []);

  return (
    <div className="homepage">
      <h1>Stephanie Salzburg's Portfolio</h1>
      <div class="container text-center">
        <div class="row">
          <div class="col">
            <h2>I am a</h2>
            <span id="typed-text"></span>
            <br />
            
              <h4>
                <div></div>
              </h4>
            </div>
          </div>
        </div>

        <div>
          <div className="homepagetext">
            <p>
              I am a passionate and skilled Full Stack Developer, with a strong
              background in creating dynamic and user-friendly websites. I
              completed a Full Stack Development bootcamp at devCodeCamp in Jun
              2023, where I learned and applied various web technologies, such
              as HTML, CSS, JavaScript, React, Node.js, Python, Django,
              Bootstrap, TailWind.css, and MySQL. I am proficient in both
              frontend and backend development, specializing in crafting
              engaging user experiences and responsive designs. I have
              experience in building scalable and robust web applications,
              utilizing modern backend technologies. I possess a keen eye for
              detail and a passion for delivering high-quality solutions that
              meet and exceed client expectations.
              <br />
              <br />I have a curious and analytical mindset, always eager to
              understand how things work and explore the consequences of
              different events. I thrive on challenges and relish the
              exhilaration that accompanies finding solutions. I excel in
              planning and organizing projects effectively, and I have a strong
              work ethic and a desire to make a positive impact.
            </p>
          </div>
          <div className="skills">
            <div class="container text-center">
              <div class="row">
                <div class="col-xs-12 col-sm-4">
                  <Frontend />
                </div>
                <div class="col-xs-12 col-sm-4">
                  <Backend />
                </div>
                <div class="col-xs-12 col-sm-4">
                  <Other />
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    
  );
};

export default HomePage;
