import React, { useEffect } from "react";
import "./ScrollLanguages.css";

const Frontend = () => {
  useEffect(() => {
    const content = document.querySelector(".content");
    const customScrollbar = document.querySelector(".custom-scrollbar");

    if (content && customScrollbar) {
      content.addEventListener("scroll", () => {
        const scrollPercentage =
          (content.scrollTop / (content.scrollHeight - content.clientHeight)) * 100;
        const scrollbarHeight = (scrollPercentage * content.clientHeight) / 100;
        customScrollbar.style.height = scrollbarHeight + "px";
      });
    }
  }, []);

  return (
    <div>
      <h2>Frontend</h2>
      <ul className="content">
        <li>React</li>
        <li>Bootstrap</li>
        <li>CSS</li>
        <li>Javascript</li>
        <li>Next.js</li>
        <li>Tailwind.css</li>
        <li>PHP</li>
        <li>jQuery</li>
        {/* Add more content here */}
      </ul>
      <div className="custom-scrollbar"></div>
    </div>
  );
};

export default Frontend;

