import React, { useEffect } from "react";
import "./ScrollLanguages.css";


const Backend = () => {
 
    
    return (  
        <div>
            <h2>Backend</h2>
            <ul>
                <li>C#</li>
                <li>Python</li>
                <li>Java</li>
                <li>Django</li>
                <li>Express.js</li>
                <li>REST API</li>
            </ul>
        </div>
        
    );
}
 
export default Backend;



