

import "./Footer.css";

const Footer = () => {


    return ( 
        <div className='footer'>
            <p>Steph's Portfolio 2023</p>{" "}
        </div>
     );
}
 
export default Footer;